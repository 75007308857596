<template>
  <Header/>
  <div class="container is-max-desktop my-bookings">
    <h1>Mine bookinger</h1>
    <div v-if="global.user.hasOwnProperty('id') && !bookings.hasOwnProperty('authentificated')">
      <p>Klik på booking-nummeret for at åbne en booking.</p>
      <div class="table-container">
        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <th>Nr.</th>
            <th>Status</th>
            <th>Start</th>
            <th>Slut</th>
            <th>Køretøj</th>
            <th class="has-text-right">Km</th>
            <th class="has-text-right">Pris</th>
            <th>Booket af</th>
          </thead>
          <tbody>
            <tr v-for="booking in bookings" :key="booking.id" 
              :class="[booking.phase <= 10 ? 'danger' : booking.phase == 11 ? 'warning' : ''] + [' phase-'] + [booking.phase]">
              <td>
                <button @click="navigate('/booking/' + booking.id, 'MyBookings')" title="Åbn booking" class="button is-small">{{ booking.id }} <i class="fas fa-chevron-right"></i></button>
              </td>
              <td class="phase">{{ booking.phase_short }}</td>
              <td>{{ formatDate(booking.start) }}</td>
              <td>{{ formatDate(booking.end) }}</td>
              <td>{{ booking.vehicleName }}</td>
              <td class="has-text-right">{{ booking.kmStandEnd - booking.kmStandStart <= 0 ? '-' : booking.kmStandEnd - booking.kmStandStart }}</td>
              <td class="phase has-text-right">{{ booking.phase == 100 ? booking.timePriceV2 + booking.distancePriceV2 : '-' }}</td>
              <td>{{ booking.usersFullName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <LoginStatus />
  </div>
  <Footer/>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LoginStatus from '@/components/LoginStatus.vue'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    return {
        global,
        apiRoot: process.env.VUE_APP_API_ROOT
    }
  },
  data() {
    return {
      bookings: []
    }
  },
  components: {
    Header,
    Footer,
    LoginStatus,
  },
  created() {
      this.bookingsLoad();
  },
  methods: {
    bookingsLoad() {
      axios.get(process.env.VUE_APP_API_ROOT + '/bookings.php?mode=account')
        .then(response => {
            this.bookings = response.data;
        })
    },
    formatDate(date) {
      if(date != undefined) {
        return date.substring(8, 10) + '.' + parseInt(date.substring(5, 7)) + '.' + date.substring(0, 4) + ' kl. ' + date.substring(11, 16);
      }
    },
    navigate(href, from) {
      this.global.navigation.navigatedFrom = from;
      this.$router.push(href);
    }
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})
</script>